import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import cx from "classnames";
import { isNil } from "ramda";

import { Link } from "../Link/Link";
import { LinkType } from "../Link/Link.types";
import { ImageAsset } from "../ImageAsset/ImageAsset";

import { ContentBlockProps } from "./ContentBlock.types";

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  description,
  image,
  link,
  isAlternate = false,
}: ContentBlockProps) => {
  return (
    <article className={cx(`m-content-block`, isAlternate && `m-content-block--alternate`)}>
      <div className="m-content-block__image-wrapper">
        {!isNil(image) && (
          <ImageAsset cn="m-content-block__image" image={image.gatsbyImageData} title={image.description} />
        )}
      </div>
      <div className="m-content-block__content">
        <h3 className="u-heading-large u-gold m-content-block__title">{title}</h3>
        <div className="m-content-block__description">{documentToReactComponents(JSON.parse(description.raw))}</div>
        {!isNil(link) && (
          <Link
            title="link.tiltle"
            href={link.href}
            type={link.isInternal ? LinkType.Internal : LinkType.External}
            cn="o-content-block__link a-link u-heading-small"
          >
            {link.title}
          </Link>
        )}
      </div>
    </article>
  );
};
