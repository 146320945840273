import React, { ReactNode } from "react";
import { isNil } from "ramda";

import Layout from "../components/Layout/Layout";
import { ContentBlock } from "../components/ContentBlock/ContentBlock";
import { Location } from "../components/Location/Location";
import { SEO } from "../components/Seo/Seo";
import { TimeTable } from "../components/TimeTable/TimeTable";

import useCompanyInfo from "../hooks/useCompanyInfo/useCompanyInfo";
import useContactPageData from "../hooks/useContactPageData/useContactPageData";
import { CompanyInfoData } from "../hooks/useCompanyInfo/useCompanyInfo.types";
import { ContactPageData } from "../hooks/useContactPageData/useContactPageData.types";
import { ContentfulBlock } from "../types/content-types";
import { getLink } from "../helpers/data/data.helper";
import { ImageAsset } from "../components/ImageAsset/ImageAsset";

const ContactPage = (): ReactNode => {
  const { title, blocks }: ContactPageData = useContactPageData();
  const {
    opening,
    image,
    contact: { location },
  }: CompanyInfoData = useCompanyInfo();

  return (
    <Layout cn="p-contact">
      <SEO title={title} lang="nl" />
      <section className="u-wrapper">
        {blocks.map(
          (block: ContentfulBlock): ReactNode => (
            <ContentBlock
              key={block.id}
              title={block.title}
              description={block.description}
              image={block.image}
              isAlternate={block.isAlternate}
              {...(!isNil(block.link) && {
                link: getLink(block.link),
              })}
            />
          )
        )}
      </section>
      <section className="u-wrapper p-contact__info">
        <h2 className="u-hidden">Contact</h2>
        {!isNil(image) && <ImageAsset cn="p-contact__image" image={image.gatsbyImageData} title={image.description} />}
        <div className="p-contact__info-content">
          <article className="p-contact__info-time">
            <h3 className="u-heading-small u-ragoon">Openingsuren</h3>
            <p className="p-contact__info-description">Enkel op afpsraak.</p>
            <TimeTable days={opening} />
          </article>
          <article className="p-contact__info-location">
            <h3 className="u-heading-small u-ragoon">Locatie</h3>
            <Location {...location} />
          </article>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
