import React from "react";

import { Day } from "../../types/globals";

import { TimeTableProps } from "./TimeTable.types";

export const TimeTable: React.FC<TimeTableProps> = ({ days }: TimeTableProps) => (
  <dl className="m-timetable">
    {days.map(({ day, times }: Day) => (
      <div key={`${day}-${times}`} className="m-timetable__item">
        <dt className="m-timetable__day u-ragoon">{day}</dt>
        <dd className="m-timetable__times">{times}</dd>
      </div>
    ))}
  </dl>
);
