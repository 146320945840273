import { useStaticQuery, graphql } from "gatsby";

import { formatContactPageData } from "../../helpers/data/data.helper";

import { ContactPageData } from "./useContactPageData.types";

export default (): ContactPageData => {
  const { contentfulPagina } = useStaticQuery(
    graphql`
      query ContactPage {
        contentfulPagina(slug: { eq: "7e848bf3-70b2-4b63-bc52-bbb488e50e08" }) {
          id
          title
          content {
            ... on ContentfulContentBlok {
              id
              title
              description {
                raw
              }
              link {
                title
                isInternal
                page {
                  title
                }
              }
              image {
                title
                description
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: AUTO
                  aspectRatio: 1.78
                  layout: FULL_WIDTH
                )
              }
              isAlternate
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return formatContactPageData(contentfulPagina);
};
